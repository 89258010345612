.search {
    padding-left: 40px;
    width: 45%;
    text-align: left;
    position: relative;
}

.search-box {
    border: none;
    border-radius: 15px;
    font-size: 1.2rem;
    width: 100%;
    box-shadow: 0.5px 1px 4px 0px #ffffffe0;
    color: 737373;
    padding: 0.5rem;
    transition: width 0.3s;
}

.search-box:focus,
.search-box:hover {
    width: 100%;
    outline: none;
    box-shadow: 2px 1px 4px 1px #ffffff;
}

.search-btn {
    height: 100%;
    width: 2em;
    margin-top: -0.7em;
    position: absolute;
    top: 40%;
    right: 0.5rem;
    opacity: 0.2;
    background-color: transparent;
    border: 0;
    background-repeat: no-repeat;
    background-size: 100%;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAQAAAAAYLlVAAACnElEQVR4AcXZsUsbYRjH8e+dh2s1SyAGJwMJuDj1BIcEhJQIOnTq5F+QOf0jIq79A7oFh7aYyVBEkaZDC3awECc1AUXRIqUQotfFocnjJe/dk+b9PKP65Md7z13ee3Uwk2SNHKmngs5TnbDLJQqjA+RYZ4OXuDzvkSYf+cAJ44fPAYFhHeAzVhlqBBGrRoax8KjSJYhRXap4KCVoECiqQQKFLC0CZbXIElOBOwJ9cUchzm2Y5QsveN4tdfY4o00HSDHHPKuUmOV5v/D5SSSJ0MXfIY+HBB55dkIvRIIIvJDR28dnFJ/9kHH0MFaVDehRxlSZnuxAFUMZunKQKBJFUQ4wXTIYqcmPZ5GoFmUEahjw5eJTJI6ivBD4jCS/csrEVZZfU4yQk5OPhrwjcoRygQ0GVdCQf73OUEfisaMkHk1HDJHkYeDX82jlBzo+kCSEyxruwDP/EK1DbsWnhHDFgNTpodWjLgY9NECKfnvoyS4p8wBngN5Z/ABtQK8dP0AH0OuYB5iMqfAAMque7HJtHmAOPdnlxjzAPHqyy5V5gFX0ZJfj8AAn9CvhoeVRol8zPMAuj/xrlhW0Vpg1D3ApflhGR3b4wTlDvI24i4u+w9y0uyVrM213U1qxuy2/Z8bui8m23VezgGW7L6cBLdIWXs9FBAsHFCLCJI9opFMKXEzkkEp/IbK0bEdI0LARQRzVWoigPKy+Z5tlWooIiuP6NhVmAEiPNwLkqHDEw5CGx2wyDQDRI8T7l80U19xwxTFNmpwzKM1nFsyeCw7jFymCAxYjrHDp8r9cUOCUYRZ4Bw6AxVV47QJYXIVXLliNsOSC1Qh/XLAa4ZuDmmIcH1l2AaytwhZfmaAkn/qOb7eYBofJekOJJX7znfccAvwFyB3OeNys7d4AAAAASUVORK5CYII=);
}

.search-btn:hover {
    outline: none;
    opacity: 0.4;
    cursor: pointer;
}

.search-btn:focus {
    outline: none;
    opacity: 0.6;
}

::-webkit-input-placeholder {
    /* Edge */
    color: gainsboro;
    font-size: 1rem;
    font-weight: 600;
}

 :-ms-input-placeholder {
    /* Internet Explorer */
    color: gainsboro;
    font-size: 1rem;
    font-weight: 600;
}

 ::placeholder {
    color: gainsboro;
    font-size: 1rem;
    font-weight: 600;
}

.custom-tab {
    width: 45%;
}

@media screen and (min-width: 0px) and (max-width: 500px) {
    .search {
        padding-left: 2px;
        width: 80%;
    }
     ::placeholder {
        color: gainsboro;
        font-size: 0.8rem;
        font-weight: 600;
    }
}