$bg: #f3f8fa;
$white: #fff;
$black: #282936;
@mixin transition( $property: all, $duration: 0.45s, $ease: cubic-bezier(0.65, 0, 0.076, 1)) {
    transition: $property $duration $ease;
}

.Ideass-Button {
    width: 5em;
    height: 3em;
    color: black;
    font-size: 2rem;
    border: 0;
    font-family: 'Lato', sans-serif;
    background-size: 100%;
    background-image: url('https://interiorsinfo.com/imagehandler.ashx?image=/articles/logo/63711/D%C3%89COR_95-3907.jpg');
    background-image: url('https://interiorsinfo.com/imagehandler.ashx?image=/articles/logo/63711/D%C3%89COR_95-3907.jpg'), linear-gradient(#eb01a5, #d13531);
    background-image: linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgba(0, 46, 164, 0.73)), url('https://interiorsinfo.com/imagehandler.ashx?image=/articles/logo/63711/D%C3%89COR_95-3907.jpg');
}

.Ideas-Button span {
    background-color: #fff;
}

.Intro {
    background: linear-gradient(to right, #007898 60%, #e2e2e2 60%);
    height: 70vh;
}

.bottom {
    position: absolute;
    bottom: 20vh;
    left: 3vw;
}

.products-h1 {
    padding-left: 2vw;
    padding-top: 10vh;
    color: white;
    /* position: absolute; */
    width: 100%;
    padding-right: 1vw;
    box-sizing: border-box;
}

.products-h1-span {
    color: pink
}

.products-p {
    color: #fff;
    padding-left: 2vw;
    padding-top: 3vh;
    width: 100%;
    padding-right: 1vw;
}

.products-div {
    width: 96%;
    padding-right: 5vw;
    height: 65vh;
    background-color: ivory;
}

.products-p-b {
    color: #fff700;
}

.svg-box {
    display: inline-block;
    height: 2vw;
    margin: 0 3px;
    position: relative;
    width: 2vw;
    background-color: white;
    padding: 2px;
}