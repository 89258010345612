/* Generals resets and unimportant stuff */

$w340px:250px;
$h500px:295px;
$w335px:245px;
$w325px:240px;
$h490px:295px;
$t386px:215px;
$w265px:250px;
$h300px:195px;
$t272px:150px;
$h520px:300px;
$h55px:55px;
* {
    margin: 0px;
    padding: 0px;
}


/* --- Product Card ---- */

#make-3D-space {
    perspective: 800px;
    transform-style: preserve-3d;
    transition: transform 5s;
}

#product-front,
#product-back {
    width: $w335px;
    height: $h500px;
    background: #fff;
    position: absolute;
    left: -5px;
    top: -5px;
    -webkit-transition: all 100ms ease-out;
    -moz-transition: all 100ms ease-out;
    -o-transition: all 100ms ease-out;
    transition: all 100ms ease-out;
}

#product-back {
    display: none;
    transform: rotateY( 180deg);
}

#product-card.animate #product-back,
#product-card.animate #product-front {
    top: 0px;
    left: 0px;
    -webkit-transition: all 100ms ease-out;
    -moz-transition: all 100ms ease-out;
    -o-transition: all 100ms ease-out;
    transition: all 100ms ease-out;
}

#product-card {
    width: $w325px;
    height: $h490px;
    position: absolute;
    top: 10px;
    left: 10px;
    overflow: hidden;
    transform-style: preserve-3d;
    -webkit-transition: 100ms ease-out;
    -moz-transition: 100ms ease-out;
    -o-transition: 100ms ease-out;
    transition: 100ms ease-out;
}

div#product-card.flip-10 {
    -webkit-transform: rotateY( -10deg);
    -moz-transform: rotateY( -10deg);
    -o-transform: rotateY( -10deg);
    transform: rotateY( -10deg);
    transition: 50ms ease-out;
}

div#product-card.flip90 {
    -webkit-transform: rotateY( 90deg);
    -moz-transform: rotateY( 90deg);
    -o-transform: rotateY( 90deg);
    transform: rotateY( 90deg);
    transition: 100ms ease-in;
}

div#product-card.flip190 {
    -webkit-transform: rotateY( 190deg);
    -moz-transform: rotateY( 190deg);
    -o-transform: rotateY( 190deg);
    transform: rotateY( 190deg);
    transition: 100ms ease-out;
}

div#product-card.flip180 {
    -webkit-transform: rotateY( 180deg);
    -moz-transform: rotateY( 180deg);
    -o-transform: rotateY( 180deg);
    transform: rotateY( 180deg);
    transition: 150ms ease-out;
}

#product-card.animate {
    top: 5px;
    left: 5px;
    width: $w335px;
    height: $h500px;
    box-shadow: 0px 13px 21px -5px rgba(0, 0, 0, 0.3);
    -webkit-transition: 100ms ease-out;
    -moz-transition: 100ms ease-out;
    -o-transition: 100ms ease-out;
    transition: 100ms ease-out;
}

.stats-container {
    text-align: left;
    background: #fff;
    position: absolute;
    top: $t386px;
    padding: 5px 20px;
    -webkit-transition: all 200ms ease-out;
    -moz-transition: all 200ms ease-out;
    -o-transition: all 200ms ease-out;
    transition: all 200ms ease-out;
    min-height: 150px;
    min-width: $w340px;
}

#product-card.animate .stats-container {
    top: $t272px;
    -webkit-transition: all 200ms ease-out;
    -moz-transition: all 200ms ease-out;
    -o-transition: all 200ms ease-out;
    transition: all 200ms ease-out;
}

.stats-container .product_name {
    font-size: 18px;
    color: #393c45;
}

.stats-container p {
    font-size: 15px;
    color: #b1b1b3;
    padding: 5px 0px;
}

.stats-container .product_price {
    float: right;
    color: #48cfad;
    font-size: 22px;
    font-weight: 600;
}

.image_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #023e6dd1;
    opacity: 0;
}

#product-card.animate .image_overlay {
    opacity: 0.7;
    -webkit-transition: all 200ms ease-out;
    -moz-transition: all 200ms ease-out;
    -o-transition: all 200ms ease-out;
    transition: all 200ms ease-out;
}

.product-options {
    padding: 2px 0 0;
}

.product-options strong {
    font-weight: 700;
    color: #393c45;
    font-size: 14px;
}

.product-options span {
    color: #969699;
    font-size: 14px;
    display: block;
    margin-bottom: 8px;
}

#view_details {
    position: absolute;
    top: 112px;
    left: 50%;
    margin-left: -85px;
    border: 2px solid #fff;
    color: #fff;
    font-size: 19px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    padding: 10px 0;
    width: 172px;
    opacity: 0;
    -webkit-transition: all 200ms ease-out;
    -moz-transition: all 200ms ease-out;
    -o-transition: all 200ms ease-out;
    transition: all 200ms ease-out;
}

#view_details:hover {
    background: #fff;
    color: #48cfad;
    cursor: pointer;
}

#product-card.animate #view_details {
    opacity: 1;
    font-size: 15px;
    top: 50px;
    -webkit-transition: all 200ms ease-out;
    -moz-transition: all 200ms ease-out;
    -o-transition: all 200ms ease-out;
    transition: all 200ms ease-out;
}

div.shadow {
    width: $w335px;
    height: $h520px;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    display: none;
    background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
    background: -o-linear-gradient(right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
    background: -moz-linear-gradient(right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
    background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
}

#product-back div.shadow {
    z-index: 10;
    opacity: 1;
    background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.1));
    background: -o-linear-gradient(right, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.1));
    background: -moz-linear-gradient(right, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.1));
    background: linear-gradient(to right, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.1));
}

#flip-back {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

#cx,
#cy {
    background: #d2d5dc;
    position: absolute;
    width: 0px;
    top: 15px;
    right: 15px;
    height: 3px;
    -webkit-transition: all 250ms ease-in-out;
    -moz-transition: all 250ms ease-in-out;
    -ms-transition: all 250ms ease-in-out;
    -o-transition: all 250ms ease-in-out;
    transition: all 250ms ease-in-out;
}

#flip-back:hover #cx,
#flip-back:hover #cy {
    background: #979ca7;
    -webkit-transition: all 250ms ease-in-out;
    -moz-transition: all 250ms ease-in-out;
    -ms-transition: all 250ms ease-in-out;
    -o-transition: all 250ms ease-in-out;
    transition: all 250ms ease-in-out;
}

#cx.s1,
#cy.s1 {
    right: 0;
    width: 30px;
    -webkit-transition: all 100ms ease-out;
    -moz-transition: all 100ms ease-out;
    -ms-transition: all 100ms ease-out;
    -o-transition: all 100ms ease-out;
    transition: all 100ms ease-out;
}

#cy.s2 {
    -ms-transform: rotate(50deg);
    -webkit-transform: rotate(50deg);
    transform: rotate(50deg);
    -webkit-transition: all 100ms ease-out;
    -moz-transition: all 100ms ease-out;
    -ms-transition: all 100ms ease-out;
    -o-transition: all 100ms ease-out;
    transition: all 100ms ease-out;
}

#cy.s3 {
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: all 100ms ease-out;
    -moz-transition: all 100ms ease-out;
    -ms-transition: all 100ms ease-out;
    -o-transition: all 100ms ease-out;
    transition: all 100ms ease-out;
}

#cx.s1 {
    right: 0;
    width: 30px;
    -webkit-transition: all 100ms ease-out;
    -moz-transition: all 100ms ease-out;
    -ms-transition: all 100ms ease-out;
    -o-transition: all 100ms ease-out;
    transition: all 100ms ease-out;
}

#cx.s2 {
    -ms-transform: rotate(140deg);
    -webkit-transform: rotate(140deg);
    transform: rotate(140deg);
    -webkit-transition: all 100ms ease-out;
    -moz-transition: all 100ms ease-out;
    -ms-transition: all 100ease-out;
    -o-transition: all 100ms ease-out;
    transition: all 100ms ease-out;
}

#cx.s3 {
    -ms-transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    -webkit-transition: all 100ease-out;
    -moz-transition: all 100ms ease-out;
    -ms-transition: all 100ms ease-out;
    -o-transition: all 100ms ease-out;
    transition: all 100ms ease-out;
}

#carousel {
    width: $w335px;
    height: $h500px;
    overflow: hidden;
    position: relative;
}

#carousel ul {
    position: absolute;
    top: 0;
    left: 0;
}

#carousel li {
    width: $w335px;
    height: $h500px;
    float: left;
    overflow: hidden;
}

.arrows-perspective {
    width: $w335px;
    height: $h55px;
    position: absolute;
    top: 50%;
    transform-style: preserve-3d;
    transition: transform 5s;
    perspective: 335px;
}

.carouselPrev,
.carouselNext {
    width: 50px;
    height: 55px;
    background: #ccc;
    position: absolute;
    top: 0;
    transition: all 200ms ease-out;
    opacity: 0.9;
    cursor: pointer;
}

.carouselNext {
    top: 0;
    right: -26px;
    -webkit-transform: rotateY( -117deg);
    -moz-transform: rotateY( -117deg);
    -o-transform: rotateY( -117deg);
    transform: rotateY( -117deg);
    transition: all 200ms ease-out;
}

.carouselNext.visible {
    right: 0;
    opacity: 0.8;
    background: #efefef;
    -webkit-transform: rotateY( 0deg);
    -moz-transform: rotateY( 0deg);
    -o-transform: rotateY( 0deg);
    transform: rotateY( 0deg);
    transition: all 200ms ease-out;
}

.carouselPrev {
    left: -26px;
    top: 0;
    -webkit-transform: rotateY( 117deg);
    -moz-transform: rotateY( 117deg);
    -o-transform: rotateY( 117deg);
    transform: rotateY( 117deg);
    transition: all 200ms ease-out;
}

.carouselPrev.visible {
    left: 0;
    opacity: 0.8;
    background: #eee;
    -webkit-transform: rotateY( 0deg);
    -moz-transform: rotateY( 0deg);
    -o-transform: rotateY( 0deg);
    transform: rotateY( 0deg);
    transition: all 200ms ease-out;
}

#carousel .x,
#carousel .y {
    height: 2px;
    width: 15px;
    background: #48cfad;
    position: absolute;
    top: 31px;
    left: 17px;
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

#carousel .x {
    -ms-transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    top: 21px;
}

#carousel .carouselNext .x {
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

#carousel .carouselNext .y {
    -ms-transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
}

.card-image {
    max-width: 100%
}