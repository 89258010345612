body,
html {
    padding: 0;
    margin: 0;
    min-width: 240px;
    color: #48525c;
    background-color: #fff;
    font-size: 16px;
    font-family: 'Lato', sans-serif;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 700px;
    display: block;
}

body {
    background-color: rgb(228, 228, 228);
}

main {
    margin: 0 auto;
    min-width: 978px;
    max-width: 1680px;
    padding: 8px;
}

@media (max-width:240px) {
    body,
    html {
        font-size: 14px
    }
}

 :target::before {
    content: '';
    display: block;
    visibility: hidden;
    height: 120px;
    margin-top: -120px
}

@media (min-width:1024px) {
    h1:target::before {
        height: 75px;
        margin-top: -75px
    }
    h2:target::before {
        height: 81px;
        margin-top: -81px
    }
    h3:target::before {
        height: 87px;
        margin-top: -87px
    }
    h4:target::before {
        height: 90px;
        margin-top: -90px
    }
    h5:target::before {
        height: 93px;
        margin-top: -93px
    }
    h6:target::before {
        height: 97px;
        margin-top: -97px
    }
}

@keyframes PULSE {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

h1,
h2,
h3,
h4,
h5 {
    margin-top: 1.5rem;
    margin-bottom: .75em;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    line-height: 1.2em;
    color: #20202a
}

h1 {
    font-size: 2.2rem;
    margin-top: 3rem
}

section.ap--content h1:first-of-type {
    margin-top: 0;
    margin-bottom: 2rem
}

h2 {
    font-size: 1.8rem;
    margin-top: 2rem
}

h3 {
    font-size: 1.414rem;
    margin-top: 1.8rem
}

h4 {
    font-size: 1.2rem
}

h5 {
    font-size: 1rem
}

.ap--content .intro,
.ap--section .intro {
    font-size: 1rem;
    padding: 0
}

.ap--content .intro *,
.ap--section .intro * {
    font-size: inherit
}

.ap--content p,
.ap--section p {
    line-height: 1.6rem;
    font-weight: 400;
    color: #48525c;
    display: block;
    margin-top: 1.1em;
    margin-bottom: 0;
    font-size: 1rem
}

.ap--content p small,
.ap--section p small {
    font-size: .875rem
}

.ap--content code,
.ap--section code {
    display: inline;
    padding: 2px 4px;
    font-family: 'Fira Mono', monospace;
    font-weight: 500;
    word-break: normal;
    color: #000;
    background-color: #eaeaea;
    font-size: .9em
}

.ap--content a,
.ap--section a {
    position: relative;
    z-index: 10
}

.ap--content a code,
.ap--section a code {
    color: #005af0;
    z-index: -1
}

.ap--content pre,
.ap--section pre {
    font-family: 'Fira Mono', monospace;
    font-weight: 500;
    font-size: 1rem;
    white-space: pre-wrap
}

.ap--content>ol,
.ap--content>ul,
.ap--section>ol,
.ap--section>ul {
    line-height: 1.6rem;
    font-weight: 400;
    color: #48525c;
    display: block;
    margin-top: 1.1em;
    margin-bottom: 0;
    padding-left: 2em;
    margin: 1.5em 0 1.5em
}

.ap--content>ol ol,
.ap--content>ol ul,
.ap--content>ul ol,
.ap--content>ul ul,
.ap--section>ol ol,
.ap--section>ol ul,
.ap--section>ul ol,
.ap--section>ul ul {
    padding-left: 2em;
    margin: 0
}

.ap--content>ol li,
.ap--content>ul li,
.ap--section>ol li,
.ap--section>ul li {
    padding-left: .5em
}

.ap--content>ol li p,
.ap--content>ul li p,
.ap--section>ol li p,
.ap--section>ul li p {
    font-size: 1em
}

.ap--content figure figcaption,
.ap--section figure figcaption {
    line-height: 1.6rem;
    font-weight: 400;
    color: #48525c;
    font-size: .8125rem;
    margin-top: .3em
}

.ap--content hr,
.ap--section hr {
    height: 1px;
    margin: .75em 0;
    background-color: #ebebf0;
    border: none
}

.ap-m-lnk {
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 0 1.5em;
    margin: 0
}

.ap-m-lnk-text {
    line-height: 1.6rem;
    font-weight: 400;
    color: #48525c;
    font-size: .875rem;
    font-weight: 700;
    color: #005af0;
    line-height: 1.3;
    transition: transform .3s cubic-bezier(.25, .1, .25, 1)
}

.ap-m-lnk-icon {
    fill: #005af0;
    padding: .438em 0;
    margin-bottom: auto;
    margin-right: .625em;
    box-sizing: initial;
    transition: transform .3s cubic-bezier(.25, .1, .25, 1)
}

.ap-m-lnk:hover .ap-m-lnk-icon,
.ap-m-lnk:hover .ap-m-lnk-text {
    transform: translateX(10px)
}

.ap-m-lnk-square {
    border-bottom: 1px solid #ebebf0
}

.ap-m-lnk-square .ap-m-lnk-text {
    font-size: 1rem;
    font-family: Poppins, sans-serif;
    font-weight: 700;
    color: #000;
    padding-left: 10px
}

.ap-m-lnk-square .ap-m-lnk-icon {
    width: 1.375em;
    height: 1.375em;
    fill: #fff;
    background-color: #005af0;
    overflow: hidden;
    padding: .563em;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, .25)
}

.ap-m-lnk-square:hover .ap-m-lnk-icon {
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, .25)
}

.ap-m-lnk-square:hover .ap-m-lnk-icon svg {
    animation: .3s cubic-bezier(.25, .1, .25, 1) duepduep
}

@keyframes duepduep {
    0% {
        transform: translate(0, 0)
    }
    49% {
        transform: translate(40px, 0)
    }
    50% {
        transform: translate(-40px, 0)
    }
    100% {
        transform: translate(0, 0)
    }
}

a {
    font-weight: 700;
    text-decoration: none;
    color: #005af0
}

.ap-a-ico {
    display: flex;
    font-size: initial;
    box-sizing: initial;
    justify-content: center;
    width: 1em;
    height: 1em
}

.ap-m-nav-link {
    font-family: Poppins, sans-serif;
    font-weight: 700;
    font-size: 1.5em;
    color: #000
}

@media (min-width:1024px) {
    .ap-m-nav-link {
        font-size: 1rem
    }
}

.ap-m-nav-link-2.ap-m-nav-link-active {
    text-decoration: underline
}

.ap-m-nav-link:hover {
    color: #005af0
}

.ap-m-nav-link-active {
    font-weight: 700;
    color: #005af0
}

.header-div {
    padding-bottom: 53px;
    position: relative;
}

.ap--header {
    width: 100%;
    max-width: 2020px;
    margin: 0 auto;
    position: fixed;
    top: 0;
    z-index: 17;
    background-color: #fff;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .07);
    height: 53px;
}

.ap--header.mainmenuopen {
    z-index: 1004
}

.ap--header.mainmenuopen~.ap--main {
    max-width: 100vw;
    overflow-x: hidden
}

@media (min-width:1024px) {
    .ap--header {
        top: 0
    }
    .ap--header.has-banner {
        top: -31px
    }
}

.ap-o-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    padding: 7px;
    background-color: #0065b4;
    /*  second option 007ddf */
    margin: 0 auto;
    position: fixed;
    width: 100%;
    min-width: 30px;
    max-height: 53px;
}

.ap-o-header-low {
    box-shadow: 0.5px 1px 4px 1px #bfbfbf1a;
    margin-bottom: 1%;
    padding-bottom: 0.4%;
    display: flex;
    flex-direction: row;
    min-width: 30px;
    position: relative;
    background-color: #fff;
}

@media (min-width:1024px) {
    .ap-o-header,
    .ap-o-header-low {
        display: flex;
        padding-left: 30px
    }
}

.ap-o-header-home {
    position: -webkit-sticky;
    position: sticky;
    display: flex;
    align-items: center;
    overflow: hidden
}

.ii-header-home-logo {
    margin-right: 6px;
    height: 32px;
}

.ii-header-home-title {
    margin-left: 4px;
    font-family: Lato, sans-serif;
    font-size: 1.5em;
    color: #fff;
    display: block;
}


/* Addition by mmmm */

@media screen and (min-width: 0px) and (max-width: 480px) {
    .ii-header-home-title {
        display: none;
    }
}

.ap-o-header-home-sub-title {
    color: #000;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden
}

.ap-o-header-main {
    display: none;
    flex: 1 1 auto;
    padding: 0 30px
}

@media (min-width:1024px) {
    .ap-o-header-main {
        display: flex
    }
}

.ap-o-header-main-item {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 18px -5px
}

.ap-o-header-main-link {
    display: flex;
    align-items: center;
    padding: 2px;
    border: 0;
    background: 0 0
}

.ap-o-header-main-link-icon {
    font-size: 8px;
    margin-left: 5px;
    transform: rotate(0);
    transition: transform .2s cubic-bezier(.25, .1, .25, 1)
}

.ap-o-header-flyout:hover,
.ap-o-header-main-link:focus~.ap-o-header-flyout,
.ap-o-header-main-link:hover~.ap-o-header-flyout {
    opacity: 1;
    pointer-events: all;
    transition: opacity .2s cubic-bezier(.25, .1, .25, 1)
}

.ap-o-header-flyout:focus-within {
    opacity: 1;
    pointer-events: all;
    transition: opacity .2s cubic-bezier(.25, .1, .25, 1)
}

.ap-o-header-flyout {
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, .2);
    position: absolute;
    top: 95%;
    left: 0;
    margin: 0;
    padding: 0 0 10px;
    pointer-events: none;
    opacity: 0;
    list-style: none;
    background: #fff;
    border-radius: 0 0 4px 4px;
    transition: opacity .2s cubic-bezier(.25, .1, .25, 1)
}

.ap-o-header-flyout:before {
    content: "";
    display: block;
    background: linear-gradient(to bottom, rgba(0, 0, 0, .07) 0, rgba(255, 255, 255, .07) 100%);
    height: 8px;
    margin-bottom: 10px
}

.ap-o-header-flyout-item-secondary {
    margin: 20px 0;
    white-space: nowrap;
    min-width: 200px
}

.ap-o-header-flyout-item-secondary a:hover {
    color: #005af0
}

.ap-o-header-flyout-item-title {
    line-height: 1.6rem;
    font-weight: 400;
    color: #48525c;
    font-size: .875rem;
    font-weight: 700;
    font-family: Poppins, sans-serif;
    font-weight: 700;
    color: #000;
    line-height: 1.4em
}

.ap-o-header-flyout-item-title.secondary {
    padding: 0 15px
}

.ap-o-header-flyout-item-description {
    line-height: 1.6rem;
    font-weight: 400;
    color: #48525c;
    font-size: .8125rem;
    opacity: .75;
    line-height: 1.25em
}

.ap-o-header-flyout-primary-item {
    position: relative;
    width: 300px
}

.ap-o-header-flyout-primary-item:hover .ap-o-header-flyout-primary-item-link {
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, .2);
    background-color: #005af0
}

.ap-o-header-flyout-primary-item:hover .ap-o-header-flyout-primary-item-link-info>* {
    color: #fff
}

.ap-o-header-flyout-primary-item:hover .ap-o-header-flyout-primary-item-link-info-stories .ap-o-header-flyout-item-title {
    color: #180ca4
}

.ap-o-header-flyout-primary-item:hover .ap-o-header-flyout-primary-item-link-info-stories .ap-o-header-flyout-item-description {
    color: #180ca4
}

.ap-o-header-flyout-primary-item:hover .ap-o-header-flyout-primary-item-link-info-email .ap-o-header-flyout-item-title {
    color: #180ca4
}

.ap-o-header-flyout-primary-item:hover .ap-o-header-flyout-primary-item-link-info-email .ap-o-header-flyout-item-description {
    color: #180ca4
}

.ap-o-header-flyout-primary-item:hover .ap-o-header-flyout-primary-item-link-icon {
    fill: #fff
}

.ap-o-header-flyout-primary-item:hover .ap-o-header-flyout-primary-item-link-icon.stories {
    fill: #180ca4
}

.ap-o-header-flyout-primary-item:hover .ap-o-header-flyout-primary-item-link-icon.email {
    fill: #180ca4
}

.ap-o-header-flyout-primary-item:hover .ap-o-header-flyout-primary-item-link.websites {
    background: linear-gradient(225deg, #00dcc0 0, #005af0 75%)
}

.ap-o-header-flyout-primary-item:hover .ap-o-header-flyout-primary-item-link.stories {
    background: linear-gradient(225deg, #ff8f00 0, #ffdc00 75%)
}

.ap-o-header-flyout-primary-item:hover .ap-o-header-flyout-primary-item-link.ads {
    background: linear-gradient(225deg, #eb49e1 0, #5500d7 75%)
}

.ap-o-header-flyout-primary-item:hover .ap-o-header-flyout-primary-item-link.email {
    background: linear-gradient(225deg, #64bf46 0, #e1eb64 75%)
}

.ap-o-header-flyout-primary-item-link {
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 10px;
    margin: 0 5px
}

.ap-o-header-flyout-primary-item-link-info {
    margin: 0 20px 0 15px
}

.ap-o-header-flyout-primary-item-link-icon {
    display: flex;
    font-size: initial;
    box-sizing: initial;
    justify-content: center;
    width: 2em;
    height: 2em
}

.ap-o-header-flyout-primary-item-link-icon.websites {
    fill: url(#gradient-websites) #000
}

.ap-o-header-flyout-primary-item-link-icon.stories {
    fill: url(#gradient-stories) #000
}

.ap-o-header-flyout-primary-item-link-icon.ads {
    fill: url(#gradient-ads) #000
}

.ap-o-header-flyout-primary-item-link-icon.email {
    fill: url(#gradient-email) #000
}

.js-is-hidden {
    display: none;
}